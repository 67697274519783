import React from 'react'

function About() {

    return (

        <div>About</div>

    )
    
}

export default About
import React from 'react'

function Work({ workId }) {

    return (
    
        <div>Work: {workId}</div>
    
    )

}

export default Work
import React from 'react'

import Hero from '../components/sections/Hero'
import Works from '../components/sections/Works'

function Home() {

    return (

        <>
        
            <Hero/>

            <Works/>

        </>

    )

}

export default Home